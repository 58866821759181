import React, { useState } from "react";
import MultiInputBox from "./components/MultiInputBox";
import PatientSymptoms from "./components/AccommodationDetails";
import useAccommodationServices from "../../services/api-services/accommodationServices";
import Swal from "sweetalert2";

const PrivateDetailModal = (props) => {
	const [inputValue, setInputValue] = useState('');

	const {
		showModalPrivateDetail,
		handleClosePrivateDetail,
		privateRoomDetailsData,
		setPrivateRoomDetailsData,
		privateRoomDetailsID,
		amenities,
		setAmenities,
		instructions,
		setInstructions,
		otherDetails,
		setOtherDetails,
		getPrivateRoomDetailsData
	} = props

	const { 
		postPrivateRoomId,
		postAccommodationDetails,
		getAccommodationDetails,
		deleteAccommodationDetails 
	} = useAccommodationServices()

	const getAccommodationDetailsList = async (params) => {
		try {
			console.log('accommodation details list ---- ', params)
			const response = await getAccommodationDetails(params)

			if (response?.success) {
				if (params.content_type == 'amenity'){
					setAmenities(response?.data)
				}else if (params.content_type == 'instruction'){
					setInstructions(response?.data)
				}else if (params.content_type == 'other'){
					setOtherDetails(response?.data)
				}
			}
		} catch { }
	}

	const addAccommodationDetails = async (value, accommodation_type, content_type, fk_ward, fk_private_room) => {
        try {
            const data = JSON.stringify({
                content: value,
				fk_ward: fk_ward,
				fk_private_room: fk_private_room,
				accomodation_type: accommodation_type,
				content_type: content_type
            })

			console.log('accommodation details----sending_data------', data);
            const response = await postAccommodationDetails(data)
            if (response?.success) {
				getPrivateRoomDetailsData()
            }
            else {
				
            }
        } catch { }
    }

	const deleteAccommodationDetailsContent = async (id, accommodation_type, content_type, fk_ward, fk_private_room) => {
		try {
			const response = await deleteAccommodationDetails(id)

			if (response?.success) {
				getPrivateRoomDetailsData()
			}
		} catch { }
	}

	return (
		<div>
			{showModalPrivateDetail && (
				<div
					className="modal show d-flex justify-content-center align-items-center"
					style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }} // Ensure height is full viewport
					tabIndex="-1"
					role="dialog"
				>
					<div className="w-75" role="document">
						<div className="modal-content  px-5" style={{ height: "35rem", maxHeight: "40rem", overflow: "scroll" }}>
							<div className="modal-header">
								<h5 className="modal-title">Private Room Details</h5>
							</div>

							<div className="row mt-3">
								<div className="col-md-6">
									{/* <div className="col-md-6"> */}
									<label htmlFor="" className="modal-label">
										Rate Per Day
									</label>
									<div className="p-2 mb-2 border bg-light rounded">
										<input type="text" name="rate_per_day" value={privateRoomDetailsData?.rate_per_day} 
										// onBlur={handleSubmitRoomId} 
										// onChange={(e) => setRoomId(e.target.value)} 
										className="w-100 h-100" />
									</div>
									<div
										className="mb-3"
										style={{ height: "50px" }}
									>
										<MultiInputBox
											label={'Available Amenities'}
											// data={value?.currentMedicalRecords && value?.currentMedicalRecords}
											data={amenities}
											onAdd={(value)=>addAccommodationDetails(
												value, 
												'room', 
												'amenity', 
												null, 
												privateRoomDetailsID
											)}
											onRemove={(id)=>deleteAccommodationDetailsContent(
												id, 
												'room',
												'amenity', 
												null, 
												privateRoomDetailsID
											)}
											containerClass={'col-12 mb-3'}
											icon={'+'}
										/>
									</div>

									<label htmlFor="" className="mt-5 modal-label">
										Other Details
									</label>
									<div
										
									>
										<PatientSymptoms
											data={otherDetails}
											onAdd={(value)=>addAccommodationDetails(
												value, 
												'room', 
												'other', 
												null, 
												privateRoomDetailsID
											)}
											onRemove={(id)=>deleteAccommodationDetailsContent(
												id, 
												'room',
												'other', 
												null, 
												privateRoomDetailsID
											)}
											iconCol={true}
										/>
									</div>
								</div>

								<div className="col-md-6">
									<label htmlFor="" className="modal-label">
										Instructions
									</label>
									<div
										className=""
										style={{ height: "90%" }}
									>
										<PatientSymptoms
											data={instructions}
											onAdd={(value)=>addAccommodationDetails(
												value, 
												'room', 
												'instruction', 
												null, 
												privateRoomDetailsID
											)}
											onRemove={(id)=>deleteAccommodationDetailsContent(
												id, 
												'room',
												'instruction', 
												null, 
												privateRoomDetailsID
											)}
											iconCol={true}
										/>
									</div>
								</div>
							</div>

							<div className="modal-footer" style={{ borderTop: "0px solid" }}>
								<button
									type="button"
									className="btn btn-secondary"
									onClick={handleClosePrivateDetail}
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default PrivateDetailModal;
