import React, { useEffect, useState } from "react";
import useAccommodationServices from "../../services/api-services/accommodationServices";
import useAccountServices from "../../services/api-services/accountServices";
import Swal from "sweetalert2";

function CenteredModal({ showModal, setShowModal, handleClose, handleShow ,singleWard }) {

  const [wardDetails, setWardDetails] = useState({});
  const {putWardUpdate} = useAccommodationServices()

  // const { getNewID } = useAccountServices()

  const handleSubmitWardDetails = async () => {
    try{
      const data = JSON.stringify(wardDetails)
      const response = await putWardUpdate(wardDetails.id,data)
      if(response?.success){
          Swal.fire('', response?.message, 'success')
      }
      else{
          Swal.fire('', response?.message, 'error')
      }
    } catch (error) {
      console.log(error,"error=========");
      
    }
  };

  const handleChangeWardAddition = (e) => {
    const { name, value } = e.target;
    if(name === "eligible_for_insurance"){
      setWardDetails((prev) => ({
        ...prev,
        [name]: !prev.eligible_for_insurance,
    }));

    }else{
      setWardDetails((prev) => ({
        ...prev,
        [name]: value,
    }));
    }
    
};



  useEffect(()=>{
    setWardDetails(singleWard)
    // return ()=>{
    //   clearStarte()
    // }
  },[singleWard])

  return (
    <div>
      {showModal && (
        <div
          className="modal show d-flex justify-content-center align-items-center"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }} // Ensure height is full viewport
          tabIndex="-1"
          role="dialog"
        >
          <div className="w-75" role="document">
            <div className="modal-content  px-4" style={{ height: "500px" }}>
              <div className="modal-header">
                <h5 className="modal-title">Ward Details</h5>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <label htmlFor="" className="modal-label">
                    Ward ID
                  </label>
                  <div className="p-2 border bg-light rounded">
                    <input type="text" name="ward_id" readOnly value={wardDetails.ward_id} className="w-100 h-100" />
                  </div>
                </div>
                <div className="col-md-8">
                  <label htmlFor="" className="modal-label">
                    Ward Name
                  </label>
                  <div className="p-2 border bg-light rounded ">
                    <input type="text" name="ward_name" value={wardDetails.ward_name} onChange={handleChangeWardAddition} className="w-100 h-100" />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4">
                  <label htmlFor="" className="modal-label">
                    Ward Type
                  </label>
                  <div className="p-2 border bg-light rounded">
                    <select
                      className="w-100 h-100 border-0 outline-0 ward-detail-select"
                      onChange={handleChangeWardAddition}
                    name="ward_type"
                    value={wardDetails.ward_type}
                      style={{
                        appearance: "none",
                        background: "none",
                        padding: "0",
                        margin: "0",
                        boxShadow: "none",
                        outline: "none",
                      }}
                    >
                      <option value="">Select </option>
                      <option value="general">general</option>
                      <option value="special">special</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-8">
                  <label htmlFor="" className="modal-label">
                    Rate Per Day
                  </label>
                  <div className="p-2 border bg-light rounded">
                    <input type="text" value={wardDetails.rate_per_day} onChange={handleChangeWardAddition} name="rate_per_day" className="w-100 h-100" />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <label htmlFor="" className="modal-label">
                    Total Bed
                  </label>
                  <div className="p-2 border bg-light rounded">
                    <input type="text" onChange={handleChangeWardAddition} value={wardDetails.total_no_of_bed} name="total_no_of_bed" className="w-100 h-100" />
                  </div>
                </div>
                <div className="col-md-8">
                  <label htmlFor=""></label>
                  <div className="p-2  rounded d-flex align-items-center ">
                    <input type="checkbox" onChange={handleChangeWardAddition} checked={wardDetails.eligible_for_insurance} name="eligible_for_insurance" className="me-2" />
                    <label htmlFor="" className="modal-label">
                      Eligible for insurence
                    </label>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <label htmlFor="" className="modal-label">
                    Amenties Available
                  </label>
                  <div
                    className=" border bg-light rounded"
                    style={{ height: "50px" }}
                  >
                    <textarea
                      name=""
                      id=""
                      className="w-100 h-100 p-2 border-0 outline-0"
                      style={{
                        appearance: "none",
                        background: "none",
                        padding: "0",
                        margin: "0",
                        boxShadow: "none",
                        borderRadius: "0",
                        outline: "none",
                        resize: "none", // Prevents resizing of the textarea
                      }}
                    ></textarea>
                  </div>

                  <label htmlFor="" className="mt-2 modal-label">
                    Other Details
                  </label>
                  <div
                    className=" border bg-light rounded"
                    style={{ height: "70px" }}
                  >
                    <textarea
                      name=""
                      id=""
                      className="w-100 h-100 p-2 border-0 outline-0"
                      style={{
                        appearance: "none",
                        background: "none",
                        padding: "0",
                        margin: "0",
                        boxShadow: "none",
                        borderRadius: "0",
                        outline: "none",
                        resize: "none", // Prevents resizing of the textarea
                      }}
                    ></textarea>
                  </div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="" className="modal-label">
                    Amenties Available
                  </label>
                  <div
                    className=" border bg-light rounded"
                    style={{ height: "145px" }}
                  >
                    <textarea
                      name=""
                      id=""
                      className="w-100 h-100 p-2 border-0 outline-0"
                      style={{
                        appearance: "none",
                        background: "none",
                        padding: "0",
                        margin: "0",
                        boxShadow: "none",
                        borderRadius: "0",
                        outline: "none",
                        resize: "none", // Prevents resizing of the textarea
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className="modal-footer" style={{ borderTop: "0px solid" }}>
                {/* <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleSubmitWardDetails}
                >
                  Save
                </button> */}
                <button className='private-add-cancel px-5 py-2 border border-primary' onClick={handleClose}>Cancel</button>
                <button className='private-add-save border border-primary px-5 py-2' onClick={handleSubmitWardDetails} >Save</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CenteredModal;
