import React, { useEffect, useState } from 'react'
// import React, { useEffect, useState } from 'react'
import { Form, Tab, TabPane,Nav } from 'react-bootstrap';
import "./Ipadmissions.css";
import useInpatientServices from "../../../../src/app/services/api-services/inpatientServices";
import moment from "moment";
import Swal from "sweetalert2";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { MdOutlineModeEditOutline } from "react-icons/md";
import HashLoader from "react-spinners/ClockLoader";
import { GoPlusCircle } from "react-icons/go";
import { BsThreeDotsVertical, BsInfoCircle } from "react-icons/bs";
import AdmPatientDetails from './AdmProcessTabs/AdmPatientDetails';
import AdmDiagnosisDetails from './AdmProcessTabs/AdmDiagnosisDetails';
import AdmBooking from './AdmProcessTabs/AdmBooking';
import './AdmissionRequest.css';
import { error } from 'pdf-lib';
const AdmissionRequest = (props) => {
  const {
    IPAdmissionList, loading,
    status, isMd, setIsMd,
    setStatus
  } = props;
  const [currentStep, setCurrentStep] = useState(1);
  const [patientData, setPatientData] = useState([]);
  const {getIpPatientDetails} = useInpatientServices();
  useEffect(() => {
    setStatus(status)
  }, [
    // pagination.page,
    // sortBy,
    // search,
    // filtered,
    // selectedDate,
    // fromDate,
    // toDate,
    // filterType,
  ]);
  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleStartAdmissionProcess = async(id) => {
    try{
      const response = await getIpPatientDetails(id)
      if (response?.success){
        setPatientData(response?.data)
        Swal.fire({
          icon: 'success',          
          title: response.message,  
          toast: true,
          position: 'top-end',
          showConfirmButton: false, 
          timer: 2000,
          timerProgressBar: true, 
          customClass: {
            popup: 'colored-toast'
          },
          background: '#f0f9eb', 
          showClass: { 
            popup: 'slide-in-right'
          },
          hideClass: { 
            popup: 'slide-out-left'
          },
        });
        setCurrentStep(2);
      }else{
        Swal.fire("Error",response.message,"error")
      }

    }catch(e){

    }
     
  };
  // const handleStartAdmissionProcess = ()=>{
  //   setCurrentStep(2);
  // }
  return (
    <div>
      {currentStep === 1 && (
    <Tab.Container activeKey={"tab1"}>
    <div className="patient-list-container">
          <Tab.Content className="patient-table-container container-white">
            <TabPane eventKey="tab1" className="">
            <div className="table-responsive">
              <table className="table patient-list-table px-2 ">
                <thead className="patient-list-table-head">
                  <tr>
                    <th style={{ width: 0 }}>Sl.no</th>
                    <th style={{ width: 0 }}>Patient ID</th>
                    <th style={{ width: 0 }}>Patient Name</th>
                    <th style={{ width: 0 }}>Transferred Physician</th>
                    <th style={{ width: 0 }}>Admitted Date</th>
                    {isMd && <th className="text-center" style={{ width: 0 }} >Action</th>}
                  </tr>
                </thead>

                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan="7">
                        <div className="d-flex justify-content-center align-items-center loader-container">
                          <HashLoader color="#e3b075" size={100} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <>
                    <tbody className="patient-list-table-body">
                      {IPAdmissionList &&
                        IPAdmissionList.map((item, index) => {
                          return (
                            <tr key={index} className="position-relative">
                              <td>
                                <label className="appointment-list-text">
                                  {index+1}
                                </label>
                              </td>

                              {/* <td onClick={() => handleShowAppointment(item)}> */}
                              <td>
                                <label
                                  className="appointment-list-text patient-id-color text-uppercase text-wrap "
                                  role="button"
                                >
                                  {item?.patient_info?.patient_id}
                                </label>
                              </td>
                              {/* <td>
                                <label className="appointment-list-text">
                                  {item?.patient_details?.mobile}
                                </label>
                              </td> */}
                              <td>
                                <label className="appointment-list-text">
                                  {item?.patient_info?.full_name}
                                </label>
                              </td>
                              <td>
                                <label className="appointment-list-text">
                                  {item?.physician_info?.first_name}
                                </label>
                              </td>
                              <td >
                                <label className="appointment-list-text">
                                  {item?.admission_date &&
                                    moment(new Date(item?.admission_date)).format(
                                      "DD/MM/YYYY"
                                    )}
                                </label>
                                
                              </td>
                              {/* <td className="">
                                {
                                  item?.admission_status == 'pending' ?
                                  <button>+ Admit</button> :
                                  <span>Admitted</span>
                                }
                              </td> */}
                              {/* {isMd ? <td className="">
                                <span
                                  className="appointment-edit-btn"
                                  // onClick={() => handleAppointmentEdit(item)}
                                >
                                  <MdOutlineModeEditOutline size={18} />
                                </span>
                              </td>:<td><BsThreeDotsVertical onClick={()=>setActiveDE(prev=>!prev)} /></td>} */}

                              <td>
                                <div className='d-flex justify-content-evenly align-items-center'>
                                      {
                                        item?.admission_status == 'pending' ?
                                        <button  onClick={()=>handleStartAdmissionProcess(item?.id)} className='admit-btn'><GoPlusCircle className='fs-4'/> Admit</button> :
                                        <span className='admitted-text'>Admitted</span>
                                      }
                                      <div className='btn'><BsInfoCircle className='fs-4'/> </div>
                                      <div className='btn'><BsThreeDotsVertical className='fs-3'/></div>
                                  </div>
                              </td>
                              {/* {isMd && <td className="">
                                <span
                                  className="appointment-del-btn"
                                  // onClick={() =>
                                  //   handleDeleteWithConfirmation(item)
                                  // }
                                >
                                  <DeleteOutlineIcon size={18} />
                                </span>
                              </td>} */}
                              {/* {activeDE && (
                                <div className="d-flex gap-3 position-absolute" style={{right:"60px"}}><span
                                className="appointment-edit-btn"
                                // onClick={() => handleAppointmentEdit(item)}
                              >
                                <MdOutlineModeEditOutline size={18} />
                              </span>
                              <span
                                  className="appointment-del-btn"
                                  // onClick={() =>
                                  //   handleDeleteWithConfirmation(item)
                                  // }
                                >
                                  <DeleteOutlineIcon size={18} />
                                </span>
                              </div>
                              )} */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </>
                )}

              </table>
              </div>
            </TabPane>
          </Tab.Content>
        </div>
        </Tab.Container>
  )}

      {currentStep === 2 && (
        <AdmPatientDetails handleNext={handleNext} handleBack={handleBack} patientDetails={patientData?.patient_basic_info} />
      )}

      {currentStep === 3 && (
        <AdmDiagnosisDetails handleNext={handleNext} handleBack={handleBack} patientData={patientData} />
      )}

      {currentStep === 4 && (
        <AdmBooking handleBack={handleBack} patientData={patientData}/>
      )}
</div>
)}

export default AdmissionRequest
