import React, { useEffect, useState } from 'react'
import AdmWardBed from './admBookingComponents/AdmWardBed';
import AdmRoomBed from './admBookingComponents/AdmRoomBed';
import useAccommodationServices from '../../../services/api-services/accommodationServices';
import useInpatientServices from '../../../services/api-services/inpatientServices';
import Swal from 'sweetalert2';
import { Navigation } from 'react-calendar';
import { useNavigate } from 'react-router-dom';
const AdmBooking = ({handleBack,patientData}) => {
    const [allWard, setAllWard] = useState([]);
    const [allRoom, setAllRoom] = useState([]);
    const [selectedWard, setSelectedWard] = useState('');
    
   
    const [selectedRoom,setSelectedRoom] = useState(null);
    const [activeBtn,setActiveBtn] = useState('ward');

    const [bedList, setBedList] = useState([]);
    const [selectedBed, setSelectedBed] = useState(null);
    const [singleWard, setSingleWard] = useState({});
    const [selectedBedDetails,setSelectedBedDetails] = useState()
    const [dischargeDate, setDischargeDate] = useState(null);
    const [difference, setDifference] = useState(null);
    const today = new Date().toISOString().split('T')[0];
    
    const navigate = useNavigate()
    console.log("Hooooi",selectedBed)
    const rooms = [
        { id: 1, number: 'Room.No007', status: 'Available' },
        { id: 2, number: 'Room.No007', status: 'Reserved' },
        { id: 3, number: 'Room.No007', status: 'Occupied' },
        { id: 4, number: 'Room.No007', status: 'Available' },
        { id: 5, number: 'Room.No007', status: 'Available' },
        { id: 6, number: 'Room.No007', status: 'Occupied' },
        { id: 7, number: 'Room.No007', status: 'Available' },
        { id: 8, number: 'Room.No007', status: 'Reserved' },
        { id: 9, number: 'Room.No007', status: 'Occupied' },
        { id: 10, number: 'Room.No007', status: 'Available' },
        { id: 11, number: 'Room.No007', status: 'Available' },
        { id: 12, number: 'Room.No007', status: 'Occupied' },
    ];
  

    const handleSelectWard = (id) => {
		setSelectedWard(id);
		const result = allWard.filter(data => data.id == id)
		console.log(result)
		setSingleWard(result[0])
		setBedList(result[0]?.beds)

	};
    const handleSelectRoom=()=>{

    }

    const { getAllWard } = useAccommodationServices()
    const { postIpAdmit } = useInpatientServices()
	useEffect(() => {
		getAllWardData()
	}, [])

    const getAllWardData = async () => {
		try {
			const response = await getAllWard()
			if (response?.success) {
				setAllWard(response?.data)
				setSelectedWard(response?.data[0]?.id)
				setSingleWard(response?.data[0])
				setBedList(response?.data[0]?.beds)
			}
		} catch { }
	}

 
    
    const handleBedSelection = (bed) => {
        if (bed.bed_status === 'available') {
        setSelectedBed(bed.id);
        setSelectedBedDetails(bed)
        }
    };

    const handleRoomSelection = (room) => {
        if (room.status === 'available') {
        setSelectedRoom(room.id);
        }
    };

   
    const handleSelectButton = (data) =>{
        setActiveBtn(data)
    }
    const handleSubmitAdmission = async()=>{
        if (selectedBed && selectedWard){
        const data = {
            admitted_date: patientData?.admission_date,
            accommodation_type:activeBtn,
            fk_bed:selectedBed,
            start_date:today,
            end_date:dischargeDate,
        }
        const response = await postIpAdmit(patientData.id, data)
        if (response.success){
            Swal.fire({
                icon: 'success',          
                title: response.message,  
                toast: true,
                position: 'top-end',
                showConfirmButton: false, 
                timer: 2000,
                timerProgressBar: true, 
                customClass: {
                  popup: 'colored-toast'
                },
                background: '#f0f9eb', 
                showClass: { 
                  popup: 'slide-in-right'
                },
                hideClass: { 
                  popup: 'slide-out-left'
                },
              })
              navigate('/')
        }
        }
    }
  return (
  <>
    <div className='col-12 p-2 ps-4 pe-5 pb-0 d-flex justify-content-between align-items-center mb-1'>
        <button className={`col-2 col-3 p-2 rounded-3 primary-bg  ${activeBtn === 'ward' ? 'active' : ''}`}  onClick={()=>handleSelectButton("ward")}>Ward</button>
        <button className={`col-2 col-3 p-2 rounded-3 primary-bg  ${activeBtn === 'room' ? 'active' : ''}`}  onClick={()=>handleSelectButton("room")}>Private Room</button>
        <button className={`col-2 col-3 p-2 rounded-3 primary-bg  ${activeBtn === 'suit' ? 'active' : ''}`}  onClick={()=>handleSelectButton("suit")}>Private Suit</button>
        <button className={`col-2 col-3 p-2 rounded-3 primary-bg  ${activeBtn === 'special' ? 'active' : ''}`}  onClick={()=>handleSelectButton("special")}>Specilaized Room</button>
    </div>
    {activeBtn === 'ward' &&  <div className='col-12 p-3 ps-4 pe-5 pb-0 d-flex justify-content-evenly align-items-center mb-1'>
        {
            allWard?.map((data, index) => (
                <p className={`d-flex align-items-center all-ward-name ${selectedWard === data.id ? 'selected-ward' : ''}`}
                    key={data.id}
                    onClick={() => handleSelectWard(data.id)}>
                    {data.ward_name}
                </p>
            ))
        }
    </div> }

    {activeBtn === 'room' &&  <div className='col-12 p-2 ps-4 pe-5 pb-0 d-flex justify-content-between align-items-center mb-1'>
        {
            allRoom?.map((data, index) => (
                <p className={`d-flex align-items-center all-ward-name ${selectedWard === data.id ? 'selected-ward' : ''}`}
                    key={data.id}
                    onClick={() => handleSelectRoom(data.id)}>
                    {data.ward_name}
                </p>
            ))
        }
    </div>}



    <div className="container-fluid mt-4 p-4 ps-3">
   
      <div className="row">
        {activeBtn === 'ward' &&  selectedWard && <AdmWardBed {...{
            bedList,
            handleBedSelection,
            selectedBed,
            singleWard,
            dischargeDate, 
            setDischargeDate, 
            difference, 
            setDifference,
            today,
        }}
        days={patientData?.duration}/>}
      {activeBtn === 'room' &&  <AdmRoomBed {...{rooms,handleRoomSelection,selectedRoom}}/>}
      </div>
    

      <div className='row'>
        <div className='co1-12 d-flex justify-content-end p-2'>
            <div className='buttonviewdown' onClick={handleBack}>Back</div>
            <div className='buttonviewdown'  onClick={handleSubmitAdmission} type='submit' disabled={!selectedBed && !selectedRoom}>Submit</div>
        </div>
        </div>
      
    </div>
    </>
  );
};

export default AdmBooking