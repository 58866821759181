import React from 'react'
import AvailableIcon from '../../../../../assets/svgs/available.svg' 
import ReservedIcon from '../../../../../assets/svgs/reserved.svg' 
import OccupiedIcon from '../../../../../assets/svgs/occupied.svg' 

const AdmRoomBed = ({rooms,handleRoomSelection,selectedRoom}) => {
    return(
    <div className='row'>
    <div className="col-8 p-3">
      <h4>Room List</h4>
      <div className="row mb-4" style={{height:"28rem",overflowY:"scroll"}}>
        {rooms.map((room,i) => (
          <div className="col-4 p-3 px-5 pt-4" key={i}>
            <div
              className={`col-12 border border-2 rounded-3 bed-card ${selectedRoom === room.id ? 'border-primary' : '' }`}
              onClick={() => handleRoomSelection(room)}
              style={{border: selectedRoom === room.id ? '2px solid blue' : '', cursor: room.status === 'available' ? 'pointer' : 'help'}}
            >
            <span className='move-icon'>
                {room.status === 'available' && <img src={AvailableIcon} width={60} height={60} />}
                {room.status === 'reserved'&&<img src={ReservedIcon} width={60} height={60}/>}
                {room.status === 'occupied'&&<img src={OccupiedIcon} width={60} height={60}/>}
            </span>
            <div className='row text-center'>
                <h6 className='col-12 p-2 pt-4'>{room.number}</h6>
                <div className='col-12 p-2 d-flex justify-content-center'>
                <div className={`col-4 p-2 rounded-3  ${ room.status === 'available'? 'available-bed': room.status === 'reserved'? 'reserved-bed': 'occupied-bed' }`}>
                {room.status}
                </div>
                </div>
            </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    
    <div className="col-4 p-4 py-3 ps-3 rounded-3" style={{backgroundColor:'#FAFAFA'}}>
      <h4>Room Details</h4>
        <div className="row mb-3">
          <span className='col-4'>Room ID</span>
          <input type="text" className=" col-8 p-2 px-1 border border-1 rounded-2" value="123" />
        </div>
        <div className="row mb-3">
          <span className='col-4'>Room Name</span>
          <input type="text" className=" col-8 p-2 px-1 border border-1 rounded-2" value="Ward01" />
        </div>
        <div className=" row mb-3">
          <span className='col-4'>Room Type</span>
          <input type="text" className=" col-8 p-2 px-1 border border-1 rounded-2" value="sample" />
        </div>
        <div className="row mb-3">
          <span className='col-4'>Total Bed</span>
          <input type="text" className=" col-8 p-2 px-1 border border-1 rounded-2" value="3" />
        </div>
        <div className="col-12 mb-3 d-flex p-1">
          <input type="checkbox" className='col-1'/> 
          <span className='col text-start'>Eligible for Insurance</span>
        </div>
        <div className=" row mb-3">
            <div className='col-12 d-flex justify-content-between' >
            <span className='col-6 text-start p-1'>Date of Discharge</span>
            <span className='col-6 text-end p-1'>Rate per Day</span>
            </div>
            <div className='col-12 d-flex justify-content-between' >
            <span className='col-6 text-start p-1'>07/10/2024</span>
            <span className='col-6 text-end p-1'>₹150.00</span>
            </div>
        </div>
        <hr />
        <h5 className='text-end p-2 fs-5 fw-400' style={{color:"#E3B075"}}>Total: ₹450.00</h5>
    </div>

</div>
)
}

export default AdmRoomBed