import React from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAxios from '../../hooks/useAxios'

const useAccommodationServices = () => {
	const axiosPrivate = useAxiosPrivate()
	const axios = useAxios()

	const postWardAddition = async (data) => {
		const response = await axiosPrivate.post('/accommodation/ward/detail/create/', data)
		return response?.data
	}

	const getAllWard = async (data) => {
		const response = await axiosPrivate.get('/accommodation/ward/detail/create/')
		return response?.data
	}

	const getWardUpdate = async (id) => {
		const response = await axiosPrivate.put(`/accommodation/ward/bed/update/${id}/`);
		return response.data;
	};

	const putWardUpdate = async (id, data) => {
		const response = await axiosPrivate.put(
			`/accommodation/ward/detail/update/${id}/`,
			data
		);
		return response.data;
	};


	const getPrivateRoomDetails = async (data) => {
		const response = await axiosPrivate.get('/accommodation/private_rooms/details/create/')
		return response?.data
	}

	const postPrivateRoomId = async (data) => {
		const response = await axiosPrivate.post('/accommodation/private_rooms/create/', data)
		return response?.data
	}

	const postPrivateRoom = async (data) => {
		const response = await axiosPrivate.post('/accommodation/private_rooms/create/', data)
		return response?.data
	}


	// Accommodation Details
	const getAccommodationDetails = async (params) =>  {
		const response = await axiosPrivate.get(
			'/accommodation/accommodation/details/create/',
			{
				params: {...params},
			}
		)
		return response?.data
	}	

	const postAccommodationDetails = async (data) => {
		const response = await axiosPrivate.post('/accommodation/accommodation/details/create/', data)
		return response?.data
	}	

	const deleteAccommodationDetails = async (id) => {
		const response = await axiosPrivate.delete(`/accommodation/accommodation/details/${id}/`);
		return response.data;
	};



	return {
		postWardAddition,
		getAllWard,
		putWardUpdate,
		getWardUpdate,
		getPrivateRoomDetails,
		postPrivateRoom,
		postPrivateRoomId,

		// Accommodation Details
		postAccommodationDetails,
		getAccommodationDetails,
		deleteAccommodationDetails
	}




}

export default useAccommodationServices
