import React from 'react'

const AdmDiagnosisDetails = ({handleNext,handleBack,patientData}) => {
  return (
    <div>      
        <div className="container-fluid mt-4 p-4 ps-3">
            <h4 className=''>Diagnosis</h4>
            <hr />
            <div className="row mb-3">
                <div className='col-6'>
                    <div className='col-12 p-1 d-flex justify-content-between'>
                        <div className="col-6 p-1">
                        <label>Admission Date</label>
                        <input type="text" className="form-control" placeholder="Admission Date" value={patientData?.admission_date} disabled />
                        </div>
                        <div className="col-6 p-1 ">
                        <label>Duration</label>
                        <input type="text" className="form-control" placeholder="Duration" value={patientData?.duration}  disabled />
                        </div>
                    </div>
                    <div className="col-12 p-1">
                    <label>Reason for Admission</label>
                    <input type="text" className="form-control" placeholder="Reason for Admission" value={patientData?.reason_for_admission}  disabled />
                    </div>
                    <div className="col-12 p-1">
                    <label>Present Issue</label>
                    <input type="text" className="form-control" placeholder="Present Issue" disabled />
                    </div>
                    <label className='p-1' >Symptoms</label>
                    <div className="col-12 p-3 rounded-3" style={{height:"10rem",overflowY:"scroll",backgroundColor:"#f4f4f4"}}>
                    <input type="text" className="form-control" placeholder="Fever" disabled />
                    </div>
                    <div className="col-12 p-1">
                    <label>ICD-10 Code</label>
                    <input type="text" className="form-control" placeholder="Remark" disabled />
                    </div>
                </div>
                <div className='col-6'>
                    <div className='col-12 p-1'>
                        <label>Prakriti Assessment</label>
                        <div className="row">
                            <div className="col">
                            <input type="text" className="form-control" placeholder="Vatta (%)" disabled />
                            </div>
                            <div className="col">
                            <input type="text" className="form-control" placeholder="Pitta (%)" disabled />
                            </div>
                            <div className="col">
                            <input type="text" className="form-control" placeholder="Kapha (%)" disabled />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 p-1">
                    <label>Remark</label>
                    <textarea className="form-control" style={{ resize: 'none',backgroundColor:"#f4f4f4" }}  rows="2" placeholder="Remark" disabled></textarea>
                    </div>
                    <div className="col-12 p-1">
                        <div className="row">
                            <div className='col-6'>
                                <div className='btn w-100' style={{backgroundColor:"#545D6E" ,color:"#fafafa"}}>Ashtavidha Pariksha</div>
                            </div>
                            <div className='col-6'>
                                <div className='btn w-100' style={{backgroundColor:"#545D6E" ,color:"#fafafa"}}>Health Assessment</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 p-1">
                    <label>Diagnosis Summary</label>
                    <textarea className="form-control"  style={{ resize: 'none',backgroundColor:"#f4f4f4" }} rows="4" placeholder="Summary content here..." disabled></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid mt-4 p-4 ps-3'>
            <div className='co1-12 d-flex justify-content-end p-2'>
                <div className='buttonviewdown' onClick={handleBack}>Back</div>
                <div className='buttonviewdown'  onClick={handleNext}>Next</div>
            </div>
        </div>
    </div>
  )
}

export default AdmDiagnosisDetails