import React, { useEffect, useState } from 'react'
import TreatmentList from './components/TreatmentList'
import DietList from './components/DietList'
import LifeList from './components/LifeList'
import useConsultationServices from '../../../../../services/api-services/consultationServices'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

const TreatmentDrop = ({value,setValue,functions}) => {

  const {
    getDietRecommended,
    getDietRestricted,
    getDietInstruction,
    getLifeStyle,
  } =useConsultationServices()

  const {
    postDietRecommended,
    postDietRestricted,
    postDietInstruction,
    postLifeStyle,
  }=useConsultationServices()

  const {
    delDietRecommended,
    delDietRestricted,
    delDietInstruction,
    delLifeStyle
  } = useConsultationServices()

  const { appointment_id } = useParams();
  const getDietRestrictedFoods = async()=>{
    try{
      const response = await getDietRestricted(appointment_id)
      if(response?.success){
        setValue?.setDietRestrictedListData(response?.data)
      }
    }catch(e){

    }
  }
  const getDietRecommentedFoods = async()=>{
    try{
      const response = await getDietRecommended(appointment_id)
      if(response?.success){
        setValue?.setDietRecommentedListData(response?.data)
      }
    }catch(e){

    }
  }
  const getDietInstructions = async()=>{
    try{
      const response = await getDietInstruction(appointment_id)
      if(response?.success){
        let list = [] 
        list = response?.data?.map((x,i)=>{
          return ({...x,value:x.instruction})
        })
        setValue?.setDietInstructionList(list)

      }
    }catch(e){

    }
  }
  const getLifeStyleModifications = async()=>{
    try{
      const response = await getLifeStyle(appointment_id)
      if(response?.success){
        let list = [] 
        list = response?.data?.map((x,i)=>{
          return ({...x,value:x.life_style_modification	})
        })
        setValue?.setLifeStyleList(list)
      }
    }catch(e){

    }
  }
  useEffect(()=>{
    getDietRestrictedFoods()
    getDietRecommentedFoods()
    getDietInstructions()
    getLifeStyleModifications()
  },[appointment_id])
  const [listActive,setListActive] = useState('treat')
  const handleActiveList=(d)=>{
    setListActive(d)
  }

  const handleAddRestricted = async()=>{
    if (!value?.dietRestricted){
      Swal.fire('', 'Please Fill out this field: Restricted', 'info')
      return
    }
    const data = JSON.stringify({
      fk_restricted_food: value?.dietRestricted,
    });
    try {
    const response = await postDietRestricted(appointment_id,data);
      if (response?.success) {
        getDietRestrictedFoods();
        setValue?.setDietRestricted('')
        Swal.fire("", response?.message, "success")
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  }

  const handleAddRecommented = async()=>{
    if (!value?.dietRecommented){
      Swal.fire('', 'Please Fill out this field: Restricted', 'info')
      return
    }
    const data = JSON.stringify({
      fk_recommended_food: value?.dietRecommented,
    });
    try {
    const response = await postDietRecommended(appointment_id, data);
      if (response?.success) {
        getDietRecommentedFoods();
        setValue?.setDietRecommented('')
        Swal.fire("", response?.message, "success")
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  }

  const handleDeleteRestricted = async(item) =>{
    try {
      const response = await delDietRestricted(item.id);
      if (response.success) {
        Swal.fire('Deleted!', 'The item has been deleted.', 'success');
        getDietRestrictedFoods();
      } else {
        Swal.fire('Error!', 'Unable to delete the item.', 'error');
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleDeleteRecommented = async(item) =>{
    try {
      const response = await delDietRecommended(item.id);
      if (response.success) {
        Swal.fire('Deleted!', 'The item has been deleted.', 'success');
        getDietRecommentedFoods();
      } else {
        Swal.fire('Error!', 'Unable to delete the item.', 'error');
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleAddDietInstructions =async(d)=>{
    // if (!value?.dietInstruction){
    //   Swal.fire('', 'Please Fill out this field: Instruction', 'info')
    //   return
    // }
    const data = JSON.stringify({
      instruction: d,
    });
    try {
    const response = await postDietInstruction(appointment_id, data);
      if (response?.success) {
        getDietInstructions();
        setValue?.setDietInstruction('')
        Swal.fire("", response?.message, "success")
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  }
  const handleDeleteDietInstructions = async(item)=>{
    try {
      console.log("~~~~~~~~~~~~~~~~~~~~~~~~~",item)
      const response = await delDietInstruction(item);
      if (response.success) {
        Swal.fire('Deleted!', 'The item has been deleted.', 'success');
        getDietInstructions();
      } else {
        Swal.fire('Error!', 'Unable to delete the item.', 'error');
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleAddLifeStyle = async(d)=>{
    // if (!value?.lifeStyle){
    //   Swal.fire('', 'Please Fill out this field: Life Style', 'info')
    //   return
    // }
    const data = JSON.stringify({
      life_style_modification: d,
    });
    try {
    const response = await postLifeStyle(appointment_id, data);
      if (response?.success) {
        getLifeStyleModifications();
        setValue?.setLifeStyle('')
        Swal.fire("", response?.message, "success")
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  }
  const handleDeleteLifeStyle = async(item)=>{
    try {
      const response = await delLifeStyle(item);
      if (response.success) {
        Swal.fire('Deleted!', 'The item has been deleted.', 'success');
        getLifeStyleModifications();
      } else {
        Swal.fire('Error!', 'Unable to delete the item.', 'error');
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div>
        <div className='col-12 d-flex justify-content-start'>
          <div className='col-1 col-2 p-4 pt-0 pe-0 pb-0 fw-bold fs-6 mouse-pointer' onClick={()=>handleActiveList('treat')}>Treatment & Therapies
          <span 
          className={`rounded w-100 ${listActive === "treat" ? 'divunderline' : ''}`}>
          </span> 
          </div>
          <div className='col-1 col-2 p-3 pt-0 ps-0 fw-bold fs-6 mouse-pointer' onClick={()=>handleActiveList('diet')}>Diet plan 
          <span 
          className={`rounded w-100 ${listActive === "diet" ? 'divunderline' : ''}`}>
          </span> 
          </div>
          <div className='col-2 col-3 p-3 pt-0 ps-0 fw-bold fs-6 mouse-pointer' onClick={()=>handleActiveList('life')}>Life style and modifications
          <span 
          className={`rounded w-100 ${listActive === "life" ? 'divunderline' : ''}`}>
          </span> 
          </div>
        </div>
       { listActive === 'treat' && <div><TreatmentList {...{value,setValue,functions}} /></div>}
       { listActive === 'diet' && <div><DietList {...{value,setValue,functions}} 
            subfunctions={{...{
                handleDeleteRestricted,
                handleDeleteRecommented,
                handleAddDietInstructions,
                handleDeleteDietInstructions,
                handleAddRestricted,
                handleAddRecommented,
                }}} /></div>}
       { listActive === 'life' && <div><LifeList {...{value,setValue,functions}}
        subfunctions={{...{
          handleAddLifeStyle,
          handleDeleteLifeStyle,
          }}}
       /></div>}
    </div>

  )
}

export default TreatmentDrop