import patient_details from "../../../../assets/pictures/icons/patient-details-icon.png";
import medical_records from "../../../../assets/pictures/icons/medical-records-icon.png";
import patient_assessment from "../../../../assets/pictures/icons/patient-assessment-icon.png";
import patient_report from "../../../../assets/pictures/icons/patient-consultation-report.png";
import patient_visiting_history from "../../../../assets/pictures/icons/patient-visiting-history.png";
import React, { useEffect, useState } from "react";
import { Nav, Tab, TabPane } from "react-bootstrap";
import PatientDetails from "./components/tabpanes/PatientDetails";
import "./PhysicianConsultation.css";
import useConsultationServices from "../../../services/api-services/consultationServices";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import MedicalRecords from "../../reception/patient/registration/components/TabPanes/MedicalRecords";
import Swal from "sweetalert2";
import usePatientServices from "../../../services/api-services/patientServices";
import useGetListDatas from "../../../services/other-services/getListDatas";
import moment from "moment";
import AssessmentInvestigation from "./components/tabpanes/AssessmentInvestigation";
import InputTextBox from '../../../shared/form-components/form-text/InputTextBox'
import Del from "../../../../assets/pictures/ant-design-clear-outlined.png"
import Rec from "../../../../assets/pictures/rec.png"
import ArrowDown from "../../../../assets/pictures/arrow-1.png"
import ArrowUp from "../../../../assets/pictures/arrow-2.png"
import VisitingHistory from "./components/tabpanes/VisitingHistory";
import useOnKey from "../../../shared/form-components/onkeydown/useOnKey";
import MedicineDrop from "./components/subdropcontents/MedicineDrop";
import TreatmentDrop from "./components/subdropcontents/TreatmentDrop";
import TreatmentList from "./components/subdropcontents/components/TreatmentList";
import MedicineList from "./components/subdropcontents/components/MedicineList";
import AudioRecorder from "./components/subdropcontents/components/AudioRecorder";
import { CgMenuGridO } from "react-icons/cg";
import TransferPopup from "./components/transferPopup/TransferPopup";
import { Modal } from 'react-bootstrap'
import useInpatientServices from "../../../services/api-services/inpatientServices";

const PhysicianConsultation = () => {
  const [key, setKey] = useState("tab1");
  const [toggle, setToggle] = useState(false);

  const [patientDetails, setPatientDetails] = useState();
  const [basicId, setBasicId] = useState("");

  // Medical records
  const [prevMedicalRecords, setPrevMedicalRecords] = useState([]);
  const [currentMedicalRecords, setCurrentMedicalRecords] = useState([]);
  const [treatmentHistory, setTreatmentHistory] = useState([]);
  const [allergyList, setAllergyList] = useState([]);
  const [patientDocuments, setPatientDocuments] = useState([]);
  const [documentCategoryList, setDocumentCategoryList] = useState("");
  const [documentCategory, setDocumentCategory] = useState("");
  const [documentIssueDate, setDocumentIssueDate] = useState("");
  const [documentValidity, setDocumentValidity] = useState("");
  const [documentList, setDocumentList] = useState(undefined);

  //Assessment
  const [prIssue, setPrIssue] = useState('')
  const [diagnosis,setDiagnosis] = useState('')
  const [vatta,setVatta] = useState('')
  const [pitta,setPitta] = useState('')
  const [kapha,setKapha] = useState('')
  const [icdCode,setIcdCode] = useState('')
  const [prRemark,setPrRemark] = useState('')
console.log("*************************prIssue: ", prIssue)
  //Prescriptions
  const [medicineName,setMedicineName] = useState('');
  const [prescId,setPrescId] = useState('');
  const [dose,setDose] = useState('');
  const [times,setTimes] = useState('');
  const [prescriptionList,setPrescriptionList] = useState([]);
  const [activeTab, setActiveTab] = useState('medicine');

  //Treatment
  const [treatmentListData,setTreatmentListData] = useState([])
  const [therapyNameList,setTherapyNameList] =useState([])
  const [durationList,setDurationList] = useState([])
  const [frequencyList,setFrequencyList]=useState([])
  const [sessionList,setSessionList]=useState([])
  const [slnoTreat,setSlnoTreat] = useState('')
  const [treatTherapyName,setTreatTherapyName] =useState('')
  const [treatDuration,setTreatDuration] = useState('')
  const [treatFrequency,setTreatFrequency]=useState('')
  const [treatSession,setTreatSession]=useState('')
  const [treatSpecificInstruction,setTreatSpecificInstruction] = useState('')
  const [treatId,setTreatId] = useState('')
  const [selectedTreatment,setSelectedTreatment] =useState('')

  //Medicine
  const [medicineListData,setMedicineListData] = useState([])
  const [medicineNameList,setMedicineNameList] = useState([])
  const [medStrengthList,setMedStrengthList] = useState([])
  const [medDosageList,setMedDosageList] = useState([])
  const [medDoseList,setMedDoseList] = useState([])
  const [medFrequencyList,setMedFrequencyList] = useState([])
  const [medQuantityList,setMedQuantityList] = useState([])
  const [medDurationList,setMedDurationList] = useState([])
  const [medName,setMedName] = useState('')
  const [medStrength,setMedStrength] = useState('')
  const [medDosage,setMedDosage] = useState('')
  const [medDose,setMedDose] = useState('')
  const [medFrequency,setMedFrequency] = useState('')
  const [medQuantity,setMedQuantity] = useState('')
  const [medDuration,setMedDuration] = useState('')
  const [medRemark,setMedRemark] = useState('')
  const [slnoMed,setSlnoMed] = useState('')
  const [medId,setMedId] = useState('')
  const [selectedMedicine,setSelectedMedicine] =useState('')

  //LabTest
  const [labTestListData,setLabTestListData] = useState([])
  const [testTabNameList,setTestTabNameList] = useState([])
  const [testTabTypeList,setTestTabTypeList] = useState([])
  const [labId,setLabId] = useState('')
  const [slnoLab,setSlnoLab] =useState('')
  const [testTabName,setTestTabName] =useState('')
  const [testTabType,setTestTabType] = useState('')
  const [testTabInstruction,setTestTabInstruction] = useState('')
  const [testTabRemark,setTestTabRemark]=useState('')
  const [selectedLabTest,setSelectedLabTest] =useState('')

  //diet 
  const [dietRestrictedListData,setDietRestrictedListData] = useState([])
  const [dietRestrictedNameList,setDietRestrictedNameList] = useState([])
  const [dietRestricted,setDietRestricted] = useState()
  const [dietRecommentedListData,setDietRecommentedListData] = useState([])
  const [dietRecommentedNameList,setDietRecommentedNameList] = useState([])
  const [dietRecommented,setDietRecommented] = useState()
  const [dietInstructionList,setDietInstructionList] = useState([])
  const [dietInstruction,setDietInstruction] = useState()
  //life
  const [lifeStyleList,setLifeStyleList] = useState([])
  const [lifeStyle,setLifeStyle] = useState()

  //admission
  const [admissionDate,setAdmissionDate] = useState('')
  const [admissionDuration,setAdmissionDuration] = useState('')
  const [admissionReason,setAdmissionReason] =useState('')

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [ref,setRef] = useState('');

  const { appointment_id } = useParams();
  const navigate = useNavigate();

  const { getPatientDetail, getMedicalPrescription,getTreatmentDetails,getMedicalPrescriptionDetails} = useConsultationServices();

  const { delMedicalPrescription,delTreatmentDetails ,delMedicinePrescriptionDetails}=useConsultationServices();

  const {postMedicalPrescription,postAppointmentAssessmentdetails,postTreatmentDetails,postMedicalPrescriptionDetails}=useConsultationServices();

  const { putPatientAppointment,putMedicalPrescription,putTreatmentDetails,putMedicinePrescriptionDetails} = useConsultationServices();

  const {
    getPatientDocumentCategories,
    getPatientMedicalIssues,
    getPatientTreatmentHistory,
    getPatientAllergicIssues,
    getPatientMedicalDocuments,
    getProperty,
  } = usePatientServices();

  const {
    postPatientMedicalIssues,
    postPatientTreatmentHistory,
    postPatientAllergicIssues,
    postPatientDocumentCategories,
    postPatientMedicalDocuments,
    postProperty,
  } = usePatientServices();
  const {
    deletePatientMedicalIssue,
    deletePatientTreatmentHistory,
    deletePatientAllergicIssues,
  } = usePatientServices();

  const { getSemanticOptionsList, getMultiInputList } = useGetListDatas();
  
  const {postTranferIp} = useInpatientServices()
  const handleTransferConfirmation = async()=>{
      postAssessmentdetails()
      if (!admissionDate){
        Swal.fire('', 'Please Fill out this field: Date of Admission', 'info')
        return
      }
      if (!admissionDuration){
        Swal.fire('', 'Please Fill out this field: Duration', 'info')
        return
      }
      if (!admissionReason){
        Swal.fire('', 'Please Fill out this field: Reason', 'info')
        return
      }
      try{
        const data={
          reason_for_admission:admissionReason,
          duration:admissionDuration,
          admission_date:moment(new Date(admissionDate)).format("YYYY-MM-DD"),
        }
      const response =await postTranferIp(appointment_id,data)
      if (response.success){
        Swal.fire({
          icon: 'success', 
          title: 'Success!',
          text: 'Patient transferred to IP successfully',
          timer: 2000, 
          showConfirmButton: false,
          toast: true,
          position: 'top-end',
        });
        navigate('/physician-appointment-list')
      } 
    }catch{}
  }
  const patientInfo = async () => {
    const response = await getPatientDetail(appointment_id);
    if (response.success) {
      console.log(response?.data?.patient_details?.details?.id)
      setPatientDetails(response?.data?.patient_details?.details);
      setBasicId(response?.data?.patient_details?.details?.id);
    }
    // console.log(response.data);
  };

  const patientDocumentCategories = async () => {
    try {
      const response = await getPatientDocumentCategories();
      if (response.success) {
        const data = response.data;
        setDocumentCategoryList(getSemanticOptionsList(data, "id", "category"));
      }
    } catch {}
  };

  const getPrevMedIssues = async () => {
    const params = {
      present: 0,
    };
    try {
      const response = await getPatientMedicalIssues(basicId, params);
      if (response?.success) {
        const issues = response?.data;
        setPrevMedicalRecords(getMultiInputList(issues, "id", "issue"));
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const getCurrentMedIssues = async () => {
    const params = {
      present: 1,
    };
    try {
      const response = await getPatientMedicalIssues(basicId, params);
      if (response?.success) {
        const issues = response?.data;
        setCurrentMedicalRecords(getMultiInputList(issues, "id", "issue"));
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const addCurrentMedIssues = async (value) => {
    try {
      const data = JSON.stringify({
        issue: value,
        is_present_issue: true,
      });
      const response = await postPatientMedicalIssues(basicId, data);
      if (response?.success) {
        getCurrentMedIssues();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const addPrevMedIssues = async (value) => {
    try {
      const data = JSON.stringify({
        issue: value,
        is_present_issue: false,
      });
      const response = await postPatientMedicalIssues(basicId, data);
      if (response?.success) {
        getPrevMedIssues();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const deleteMedIssue = async (issueId) => {
    try {
      const response = await deletePatientMedicalIssue(basicId, issueId);
      if (response?.success) {
        getCurrentMedIssues();
        getPrevMedIssues();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const getPatientTreatmentRecords = async () => {
    try {
      const response = await getPatientTreatmentHistory(basicId);
      if (response?.success) {
        const treatments = response?.data;
        setTreatmentHistory(getMultiInputList(treatments, "id", "treatment"));
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const addTreatment = async (value) => {
    try {
      const data = JSON.stringify({
        treatment: value,
      });
      const response = await postPatientTreatmentHistory(basicId, data);
      if (response?.success) {
        getPatientTreatmentRecords();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const removeTreatment = async (treatmentId) => {
    try {
      const response = await deletePatientTreatmentHistory(
        basicId,
        treatmentId
      );
      if (response?.success) {
        getPatientTreatmentRecords();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const getPatientAllergicRecords = async () => {
    try {
      const response = await getPatientAllergicIssues(basicId);
      if (response?.success) {
        const issues = response?.data;
        setAllergyList(getMultiInputList(issues, "id", "issue"));
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const addAllergy = async (value) => {
    try {
      const data = JSON.stringify({
        issue: value,
      });
      const response = await postPatientAllergicIssues(basicId, data);
      if (response?.success) {
        getPatientAllergicRecords();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const deleteAllergy = async (issueId) => {
    try {
      const response = await deletePatientAllergicIssues(basicId, issueId);
      if (response?.success) {
        getPatientAllergicRecords();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const getPatientDocuments = async () => {
    try {
      const response = await getPatientMedicalDocuments(basicId);
      if (response?.success) {
        setPatientDocuments(response?.data);
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const postPatientDocumentType = async (value) => {
    try {
      const data = JSON.stringify({
        category: value.value,
      });
      const response = await postPatientDocumentCategories(data);
      if (response?.success) {
        setDocumentCategory(response?.data?.id);
        patientDocumentCategories();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const handleDocumentUpload = async () => {
    //set Medical Document Data
    const medicalDocuments = new FormData();
    medicalDocuments.append("category", documentCategory);
    medicalDocuments.append(
      "issued_date",
      documentIssueDate &&
        moment(new Date(documentIssueDate)).format("YYYY-MM-DD")
    );
    medicalDocuments.append(
      "validity_upto",
      documentValidity &&
        moment(new Date(documentValidity)).format("YYYY-MM-DD")
    );
    documentList?.map((file, index) => {
      medicalDocuments.append("document" + (index + 1), file);
    });
    // console.log(medicalDocuments)
    try {
      const response = await postPatientMedicalDocuments(
        basicId,
        medicalDocuments
      );
      if (response?.success) {
        setDocumentCategory("");
        setDocumentIssueDate("");
        setDocumentValidity("");
        setDocumentList(undefined);
        getPatientDocuments();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };
  
  const {handleKeyDown,formRef} = useOnKey(ref,setRef);

  const handleKeySubmit = (e)=>{
    if (e.type=="keydown"){
      console.log(e)
      if(e.key == "Enter"){
        if(prescId!=''){
          putPrescription()
        }
        else{
          postPrescription()
        }
      }
    }
  }
  const getPrescription =async()=>{
    try{
        const response = await getMedicalPrescription(appointment_id)
        if(response?.success){
          setPrescriptionList(response?.data)
        }
    }catch(err){
        console.log(err?.response)
    }
}

const postPrescription = async()=>{
    try{
        const data={
          medicine_name:medicineName,
          dose:dose,
          days:times,
        }
        const response = await postMedicalPrescription(appointment_id ,data)
        if(response?.success){
          getPrescription()
          setMedicineName('')
          setDose('')
          setTimes('')
            Swal.fire({
                text:response?.message,
                icon:'success',
                timer: 1000,
                showConfirmButton:false
            })
        }
    }catch(err){
        console.log(err?.response)
    }
}

const handlePrescriptionEdit =(data)=>{
  setPrescId(data?.id)
  setMedicineName(data?.medicine_name)
  setDose(data?.dose)
  setTimes(data?.days)
}

const putPrescription = async()=>{
  try{
      const data={
        medicine_name:medicineName,
        dose:dose,
        days:times,
      }
      const response = await putMedicalPrescription(prescId ,data)
      if(response?.success){
        getPrescription()
        setPrescId('')
        setMedicineName('')
        setDose('')
        setTimes('')
          Swal.fire({
              text:response?.message,
              icon:'success',
              timer: 1000,
              showConfirmButton:false
          })
      }
  }catch(err){
      console.log(err?.response)
  }
}

const delPrescription =async(id)=>{
  try{
      const response = await delMedicalPrescription(id)
      if(response?.success){
        getPrescription()
        Swal.fire({
          text:response?.message,
          icon:'success',
          timer: 1000,
          showConfirmButton:false
      })
      }
  }catch(err){
      console.log(err?.response)
  }
}

const deletePress = async (item) => {
  Swal.fire({
    title: 'Delete',
    text: `Are you sure to delete ${item.medicine_name} medicine ?`,
    icon: "question",
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: "Yes",
    denyButtonText: "Cancel",
  }).then(async (result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: 'Delete',
        text: `Confirm deletion`,
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        denyButtonText: "Cancel",
        showLoaderOnConfirm: true,
        preConfirm: async () => {

          await delPrescription(item.id)
        },
        preDeny: () => {
          Swal.fire('Cancelled', '', 'info')
        }
      })
    } 
    })
  }
  const postAssessmentdetails = async()=>{
    try{
        const savedAssessment = localStorage.getItem('assessment');
        const data=JSON.parse(savedAssessment&&savedAssessment)
        const response = await postAppointmentAssessmentdetails(appointment_id ,data)
        if(response?.success){
          const assessment = {
            present_issue: '',
            diagnosis: '',
            icd_10_code: '',
            vatta: '',
            pita: '', 
            kapha: '',
            remark: ''
          };
          localStorage.setItem('assessment', JSON.stringify(assessment));
            Swal.fire({
              icon: 'success',          
              title: response.message,  
              toast: true,
              position: 'top-end',
              showConfirmButton: false, 
              timer: 2000,
              timerProgressBar: true, 
              customClass: {
                popup: 'colored-toast'
              },
              background: '#f0f9eb', 
              showClass: { 
                popup: 'slide-in-right'
              },
              hideClass: { 
                popup: 'slide-out-left'
              },
            })
        }
    }catch(err){
        console.log(err?.response)
    }
  }
  const handleFinish= async()=>{
    postAssessmentdetails()
    const data = {
      status:'completed',
      is_registered_patient: true,
    }
    const response = await putPatientAppointment(appointment_id, data)
    if(response?.success){
        Swal.fire({
          icon: 'success',          
          title: response.message,  
          toast: true,
          position: 'top-end',
          showConfirmButton: false, 
          timer: 2000,
          timerProgressBar: true, 
          customClass: {
            popup: 'colored-toast'
          },
          background: '#f0f9eb', 
          showClass: { 
            popup: 'slide-in-right'
          },
          hideClass: { 
            popup: 'slide-out-left'
          },
        })
        navigate('/physician-appointment-list')
    }
  }

    // const getProperties = async () =>{
    //     try{
    //         const types =["medicine_name","strength","dosage","dose","frequency","total_quantity","duration"]
    //         for (let i in types){
    //           const data = {type:i}
    //           const response = await getProperty(data)
    //           if (response?.success){
    //               const temp = response?.data
    //               let list=[]
    //               temp.map((d,i)=>{
    //                   list.push({
    //                       text:d.property_value,
    //                       value:d.id,
    //                       type:d.property_type,
    //                   })
    //               })
    //               if (data?.type ==="name_of_therapy")
    //                 setTherapyNameList(list)
    //               if (data?.type ==="t_duration")
    //               setDurationList(list)
    //               if (data?.type ==="t_frequency")
    //               setFrequencyList(list)
    //               if (data?.type ==="treat_session")
    //               setSessionList(list)
    //               if (data?.type ==="medicine_name")
    //               setMedicineNameList(list)
    //               if (data?.type ==="strength")
    //               setMedStrengthList(list)
    //               if (data?.type ==="dosage")
    //               setMedDosageList(list)
    //               if (data?.type ==="dose")
    //               setMedDoseList(list)
    //               if (data?.type ==="frequency")
    //               setMedFrequencyList(list)
    //               if (data?.type ==="total_quantity")
    //               setMedQuantityList(list)
    //               if (data?.type ==="duration")
    //               setMedDurationList(list)
    //             // setTherapyNameList(list.filter((d)=>d.type==="name_of_therapy"))
    //             // setDurationList(list.filter((d)=>d.type==="t_duration"))
    //             // setFrequencyList(list.filter((d)=>d.type==="t_frequency"))
    //             // setSessionList(list.filter((d)=>d.type==="treat_session"))
    //             // setMedicineNameList(list.filter((d)=>d.type==="medicine_name"))
    //             // setMedStrengthList(list.filter((d)=>d.type==="strength"))
    //             // setMedDosageList(list.filter((d)=>d.type==="dosage"))
    //             // setMedDoseList(list.filter((d)=>d.type==="dose"))
    //             // setMedFrequencyList(list.filter((d)=>d.type==="frequency"))
    //             // setMedQuantityList(list.filter((d)=>d.type==="total_quantity"))
    //             // setMedDurationList(list.filter((d)=>d.type==="duration"))

    //         }else {
    //             Swal.fire("", response?.message, "error")
    //         }
    //       }
    //     }
    //     catch(err){

    //     }
    // }
    const types = ["medicine_name", "strength", "dosage", "dose", "frequency", "total_quantity", "duration","total_session","name_of_therapy","t_duration","t_frequency","medical_test_name","medical_test_type",'recommended_food','restricted_food'];
    const setupData = {
      name_of_therapy: setTherapyNameList,
      t_duration: setDurationList,
      t_frequency: setFrequencyList,
      total_session: setSessionList,
      medicine_name: setMedicineNameList,
      strength: setMedStrengthList,
      dosage: setMedDosageList,
      dose: setMedDoseList,
      frequency: setMedFrequencyList,
      total_quantity: setMedQuantityList,
      duration: setMedDurationList,
      medical_test_name:setTestTabNameList,
      medical_test_type:setTestTabTypeList,
      recommended_food:setDietRecommentedNameList,
      restricted_food:setDietRestrictedNameList,
    };

    const getProperties = async () => {
      try {
        const promises = types.map((type) => {
          const data = { type };
          return getProperty(data).then((response) => {
            if (response?.success) {
              const list = response?.data.map((d) => ({
                text: d.property_value,
                value: d.id,
                type: d.property_type
              }));
              return { type, list };
            } else {
              throw new Error(response?.message);
            }
          });
        });

        const results = await Promise.all(promises);

        results.forEach(({ type, list }) => {
          if (setupData[type]) {
            setupData[type](list);
          }
        });
      } catch (error) {
        Swal.fire("", error.message, "error");
      }
    };



    
    const addPatientProperty = async (value,state) => {
        try {
            let submitData = { property_value: value, property_type: state };
            const response = await postProperty(submitData)
            if (response?.success) {
                getProperties()
                if (state === "name_of_therapy"){
                  setTreatTherapyName(response?.data?.id)
                }
                if (state === "t_duration"){
                  setTreatDuration(response?.data?.id)
                }
                if (state === "t_frequency"){
                  setTreatFrequency(response?.data?.id)
                }
                if (state === "total_session"){
                  setTreatSession(response?.data?.id)
                }
                if (state === "medicine_name"){
                  setMedName(response?.data?.id)
                }
                if (state === "strength"){
                  setMedStrength(response?.data?.id)
                }
                if (state === "dosage"){
                  setMedDosage(response?.data?.id)
                }
                if (state === "dose"){
                  setMedDose(response?.data?.id)
                }
                if (state === "frequency"){
                  setMedFrequency(response?.data?.id)
                }
                if (state === "total_quantity"){
                  setMedQuantity(response?.data?.id)
                }
                if (state === "duration"){
                  setMedDuration(response?.data?.id)
                }
                if (state === "medical_test_name"){
                  setTestTabName(response?.data?.id)
                }
                if (state === "medical_test_type"){
                  setTestTabType(response?.data?.id)
                }
                if (state === "recommended_food"){
                  setDietRecommented(response?.data?.id)
                }
                if (state === "restricted_food"){
                  setDietRestricted(response?.data?.id)
                }
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const getTreatmentListData = async()=>{
      try{
        const response  = await getTreatmentDetails(appointment_id)
        if (response.success){
          setTreatmentListData(response.data)
        }
      }catch(err){

      }
    }

    const handleTreatmentSubmit = async(e)=>{
      e.preventDefault()
      if (!treatTherapyName){
        Swal.fire('', 'Please Fill out this field: Name of Therapy', 'info')
        return
      }
      if (!treatDuration){
        Swal.fire('', 'Please Fill out this field: Duration', 'info')
        return
      }
      if (!treatFrequency){
        Swal.fire('', 'Please Fill out this field: Frequency', 'info')
        return
      }
      if (!treatSession){
        Swal.fire('', 'Please Fill out this field: Total Session', 'info')
        return
      }
      // if (!treatSpecificInstruction){
      //   Swal.fire('', 'Please Fill out this field: Specific Instruction', 'info')
      //   return
      // }
      const treatmentDetails =new FormData()
      treatmentDetails.append('fk_name_of_therapy',treatTherapyName)
      treatmentDetails.append('fk_t_duration',treatDuration)
      treatmentDetails.append('fk_t_frequency',treatFrequency)
      treatmentDetails.append('fk_total_session',treatSession)
      treatmentDetails.append('specific_instruction',treatSpecificInstruction)
      try{
        const response =await postTreatmentDetails(appointment_id, treatmentDetails)
        if (response?.success) {
          console.log("#RESponse",response.data)
          getTreatmentListData()
          setTreatTherapyName('')
          setTreatDuration('')
          setTreatFrequency('')
          setTreatSession('')
          setTreatSpecificInstruction('')
          Swal.fire("", response?.message, "success")
      }
      else {
          Swal.fire("", response?.message, "error")
      }
      }catch(e){
        console.log(e)
      }
    }

    const handleTreatmentEdit = async(data) =>{
      try{
        const response = await putTreatmentDetails(treatId ,data)
        if(response?.success){
          getTreatmentListData()
          setTreatTherapyName('')
          setTreatDuration('')
          setTreatFrequency('')
          setTreatSession('')
          setTreatSpecificInstruction('')
          setSlnoTreat('')
          setTreatId('')
          setSelectedTreatment('')
          Swal.fire({
            text:response?.message,
            icon:'success',
            timer: 2000,
            showConfirmButton:false
        })
      }
    } catch (e){
      console.log(e)
    }
    }

    const handleTreatmentDelete = (item) =>{
      Swal.fire({
        title: 'Delete',
        text: `Are you sure to delete ${item?.name_of_therapy?.property_value} treatment ?`,
        icon: "question",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "Cancel",
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Delete',
            text: `Confirm deletion`,
            icon: 'warning',
            showDenyButton: true,
            showCancelButton: false,
            denyButtonText: "Cancel",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
              try {
                const response = await delTreatmentDetails(item.id);
                if (response.success) {
                  Swal.fire('Deleted!', 'The item has been deleted.', 'success');
                  getTreatmentListData();
                } else {
                  Swal.fire('Error!', 'Unable to delete the item.', 'error');
                }
              } catch (error) {
                console.log(error)
              }
            },
            preDeny: () => {
              Swal.fire('Cancelled', '', 'info')
            }
          })
        } 
        })
      }
    const handleSubmitTreat = (e)=>{
        if(treatId!=''){
          const data={
            fk_name_of_therapy:treatTherapyName,
            fk_t_duration:treatDuration,
            fk_t_frequency:treatFrequency,
            fk_total_session:treatSession,
            specific_instruction:treatSpecificInstruction
          }
          handleTreatmentEdit(data)
        }
        else{
          handleTreatmentSubmit(e)
        }
    }

    const getMedicineListData = async()=>{
      try{
        const response  = await getMedicalPrescriptionDetails(appointment_id)
        if (response.success){
          setMedicineListData(response.data)
        }
      }catch(err){
      }
    }


    const handleMedicineEdit = async(data) =>{
        try{
          const response = await putMedicinePrescriptionDetails(medId ,data)
          if(response?.success){
            getMedicineListData()
            setMedName('')
            setMedStrength('')
            setMedDosage('')
            setMedDose('')
            setMedFrequency('')
            setMedQuantity('')
            setMedDuration('')
            setMedRemark('')
            setSlnoMed('')
            setMedId('')
            setSelectedMedicine('')
            Swal.fire({
              text:response?.message,
              icon:'success',
              timer: 2000,
              showConfirmButton:false
          })
        }
      } catch (e){
        console.log(e)
      }
    }

    const handleMedicineSubmit = async(e)=>{
      e.preventDefault()
      if (!medName){
        Swal.fire('', 'Please Fill out this field: Medicine Name', 'info')
        return
      }
      if (!medStrength){
        Swal.fire('', 'Please Fill out this field: Strength/Unit', 'info')
        return
      }
      if (!medDosage){
        Swal.fire('', 'Please Fill out this field: Dosage', 'info')
        return
      }
      if (!medDose){
        Swal.fire('', 'Please Fill out this field: Dose', 'info')
        return
      }
      if (!medFrequency){
        Swal.fire('', 'Please Fill out this field: Frequancy & Timing', 'info')
        return
      }
      if (!medQuantity){
        Swal.fire('', 'Please Fill out this field: Total Quantity', 'info')
        return
      }
      if (!medDuration){
        Swal.fire('', 'Please Fill out this field: Duration', 'info')
        return
      }
      const medicineDetails =new FormData()
      medicineDetails.append('fk_medicine_name',medName)
      medicineDetails.append('fk_strength',medStrength)
      medicineDetails.append('fk_dosage',medDosage)
      medicineDetails.append('fk_dose',medDose)
      medicineDetails.append('fk_frequency',medFrequency)
      medicineDetails.append('fk_total_quantity',medQuantity)
      medicineDetails.append('fk_duration',medDuration)
      medicineDetails.append('remark',medRemark)
      try{
        const response =await postMedicalPrescriptionDetails(appointment_id, medicineDetails)
        if (response?.success) {
          console.log("#Med_Response",response.data)
          getMedicineListData()
          setMedName('')
          setMedStrength('')
          setMedDosage('')
          setMedDose('')
          setMedFrequency('')
          setMedQuantity('')
          setMedDuration('')
          setMedRemark('')
          Swal.fire("", response?.message, "success")
      }
      else {
          Swal.fire("", response?.message, "error")
      }
      }catch(e){
        console.log(e)
      }
    }
    const handleMedicineDelete = (item) =>{
      Swal.fire({
        title: 'Delete',
        text: `Are you sure to delete ${item?.medicine_name?.property_value} medicine ?`,
        icon: "question",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "Cancel",
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Delete',
            text: `Confirm deletion`,
            icon: 'warning',
            showDenyButton: true,
            showCancelButton: false,
            denyButtonText: "Cancel",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
              try {
                const response = await delMedicinePrescriptionDetails(item.id);
                if (response.success) {
                  Swal.fire('Deleted!', 'The item has been deleted.', 'success');
                  getMedicineListData();
                } else {
                  Swal.fire('Error!', 'Unable to delete the item.', 'error');
                }
              } catch (error) {
                console.log(error)
              }
            },
            preDeny: () => {
              Swal.fire('Cancelled', '', 'info')
            }
          })
        } 
        })
      }
    const handleSubmitMedicine = (e) =>{
      if(medId!=''){
        const data={
          fk_medicine_name:medName,
          fk_strength:medStrength,
          fk_dosage:medDosage,
          fk_dose:medDose,
          fk_frequency:medFrequency,
          fk_total_quantity:medQuantity,
          fk_duration:medDuration,
          remark:medRemark,
        }
        handleMedicineEdit(data)
      }
      else{
        handleMedicineSubmit(e)
      }
    }
    const [menuPopup,setMenuPopup] =useState(false)
    const handleMenuPopup = ()=>{
      setMenuPopup(!menuPopup)
    }

    const [transferPopup,setTransferPopup] =useState(false)
    const handleTransferPopup = ()=>{
      setTransferPopup(!transferPopup)
    }
  useEffect(() => {
    if(appointment_id){
      patientInfo();
      getPrescription();
    } 
    getProperties()
    patientDocumentCategories();
  }, []);

  useEffect(()=>{
    getTreatmentListData()
    getMedicineListData()
  },[appointment_id])

  useEffect(() => {
    if (basicId) {
      getPrevMedIssues();
      getCurrentMedIssues();
      getPatientTreatmentRecords();
      getPatientAllergicRecords();
      getPatientDocuments();
    }
  }, [basicId]);
  return (
    <div className="">
      <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
        <div className="col-12 row" style={{height:".5rem"}}>
          <Nav className="tabpane-container col-6 ps-5">
            <Nav.Item className="content">
              <Nav.Link className="tabpane-item" eventKey="tab1">
                <img src={patient_details} className="details-icon" alt="" />
                &nbsp; Patient Details
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="content">
              <Nav.Link className="tabpane-item" eventKey="tab2">
                <img src={medical_records} className="medical-icon" alt="" />
                &nbsp; Medical Records
              </Nav.Link>
            </Nav.Item>
            {/* <Nav.Item className="content">
              <Nav.Link className="tabpane-item" eventKey="tab3">
                <img
                  src={patient_assessment}
                  className="physician-documents-icon"
                  alt=""
                />
                &nbsp; Assessment & Investigation
              </Nav.Link>
            </Nav.Item> */}
            <Nav.Item className="content">
              <Nav.Link className="tabpane-item" eventKey="tab4">
                <img
                  src={patient_report}
                  className="physician-documents-icon"
                  alt=""
                />
                &nbsp; Report
              </Nav.Link>
            </Nav.Item>
            </Nav>
            <div className='d-flex justify-content-end col-6 pe-4'>
              <div
                className='buttonview me-1'
                >
                Cancel
              </div>
              <div
                className='buttonview me-1'
                onClick={postAssessmentdetails}
                >
                Save
              </div>
              <div className={`${menuPopup?"menu-popup":''}`}>
              <div
                className="buttonview me-5"
                onClick={handleFinish}
                >
                Finish
              </div>
              {menuPopup && 
                <button 
                className="btn btn-success mt-3" 
                style={{backgroundColor:"#2FC699",borderColor:"#2FC699",boxShadow:"0px 0px 2.5px grey"}}
                onClick={handleTransferPopup}
                >
                Transfer IP
                </button>
              }
              </div>
              <div
              style={{position:"relative",top:"-.1rem" ,left:"-1.5rem"}}
              onClick={handleMenuPopup}
              >
                <CgMenuGridO size={36} style={{color:'#1F5EA7'}}/>
              </div>
              <div>
                
              </div>
            </div>
          
           
        </div>
        <div className="pagebody-container">
          <Tab.Content className="tabcontent-container">
            <TabPane eventKey="tab1" className="">
              <PatientDetails
                {...{
                  patientDetails,
                }}
              />
            </TabPane>
            <TabPane eventKey="tab2" className="">
              <MedicalRecords
                value={{
                  ...{
                    prevMedicalRecords,
                    currentMedicalRecords,
                    treatmentHistory,
                    allergyList,
                    documentCategoryList,
                    documentCategory,
                    documentIssueDate,
                    documentValidity,
                    documentList,
                    patientDocuments,
                  },
                }}
                setValue={{
                  ...{
                    setPrevMedicalRecords,
                    setCurrentMedicalRecords,
                    setTreatmentHistory,
                    setAllergyList,
                    setDocumentCategory,
                    setDocumentIssueDate,
                    setDocumentValidity,
                    setDocumentList,
                  },
                }}
                functions={{
                  ...{
                    addCurrentMedIssues,
                    addPrevMedIssues,
                    addTreatment,
                    addAllergy,
                    deleteMedIssue,
                    removeTreatment,
                    deleteAllergy,
                    handleDocumentUpload,
                    postPatientDocumentType,
                  },
                }}
                consult={true}
                setKey={setKey}
                borderLook={true}
              />
            </TabPane>
            {/* <TabPane eventKey="tab3" className="mb-5 pb-3">
            <AssessmentInvestigation
              value={{
                ...{
                  prIssue,
                  diagnosis
                }
              }}
              setValue={{
                ...{
                  setPrIssue,
                  setDiagnosis,
                }
              }}
            
            />
            </TabPane> */}
            <TabPane eventKey="tab4" className="">

            <VisitingHistory
              {...{basicId}}
            />

            </TabPane>
            
          </Tab.Content>
          <div className={`fixed ${toggle? 'popup':''}`} >

            <div className={`bottom_content ${toggle? 'popup':''} mb-5`} style={{backgroundColor:"#ffffff"}}> 
                <button
                  className="bottom_content_btn btn mx-4 py-2 text-light"
                  style={{backgroundColor:"#986949"}}
                  onClick={()=>setToggle(!toggle)}>
                    {toggle ? (
                      <>
                        <img src={ArrowDown} alt="Arrow Down" className="pe-3"/>Back
                      </>
                    ) : (
                      <>
                        <img src={ArrowUp} alt="Arrow Up" className="pe-3"/>Enter Prescription & Assessment
                      </>
                    )}
                </button>		
                <div className="d-flex justify-content-start align-items-center ps-3 w-100 py-2">
                  <div className="col-3 col-4">
                    <AudioRecorder {...{
                      patientDetails,
                    }}/>
                  </div>
                  {/* <button className={`col-2 col-3 p-3 rounded-3 sample-button ${activeTab === 'medicine' ? 'active' : ''}`} onClick={() => handleTabClick('medicine')}>Medicine</button>
                  <button className={`col-2 col-3  p-3 rounded-3 sample-button ${activeTab === 'assessment' ? 'active' : ''}`} onClick={() => handleTabClick('assessment')}>Diagnosis/Assessment</button>
                  <button className={`col-2 col-3  p-3 rounded-3 sample-button ${activeTab === 'treatment' ? 'active' : ''}`} onClick={() => handleTabClick('treatment')}>Treatment & Therapy</button>
                  <button className={`col-2 col-3  p-3 rounded-3 sample-button ${activeTab === 'followup' ? 'active' : ''}`} onClick={() => handleTabClick('followup')}>Followup & Progress</button> */}
                </div>
            </div>
            
            <div className='content'>
             
              <div className="container pb-3 pt-0 mx-0 w-100" style={{maxWidth:'100%'}}>
              <div className="d-flex justify-content-evenly w-100 py-2 mb-4 ">
                  <button className={`col-2 col-3 p-3 rounded-3 sample-button ${activeTab === 'medicine' ? 'active' : ''}`} onClick={() => handleTabClick('medicine')}>Medicine</button>
                  <button className={`col-2 col-3  p-3 rounded-3 sample-button ${activeTab === 'assessment' ? 'active' : ''}`} onClick={() => handleTabClick('assessment')}>Diagnosis/Assessment</button>
                  <button className={`col-2 col-3  p-3 rounded-3 sample-button ${activeTab === 'treatment' ? 'active' : ''}`} onClick={() => handleTabClick('treatment')}>Treatment & Therapy</button>
                  <button className={`col-2 col-3  p-3 rounded-3 sample-button ${activeTab === 'followup' ? 'active' : ''}`} onClick={() => handleTabClick('followup')}>Followup & Progress</button>
              </div>
              {/* {activeTab === 'medicine' && ( <MedicineDrop {...{ 
                formRef,
                medicineName,
                setMedicineName,
                dose,
                setDose,
                times,
                setTimes,
                handleKeyDown,
                handleKeySubmit,
                prescriptionList,
                handlePrescriptionEdit,
                deletePress,
                Del
              }}/>)} */}

              {activeTab === 'medicine' && ( <MedicineList 
              value={{...{
                medName,
                medStrength,
                medDosage,
                medDose,
                medFrequency,
                medQuantity,
                medDuration,
                medRemark,
                slnoMed,
                medId,
                selectedMedicine,
                medStrengthList,
                medDosageList,
                medDoseList,
                medFrequencyList,
                medQuantityList,
                medDurationList,
                medicineListData,
                medicineNameList,
              }}}
              setValue={{...{
                setMedName,
                setMedStrength,
                setMedDosage,
                setMedDose,
                setMedFrequency,
                setMedQuantity,
                setMedDuration,
                setMedRemark,
                setSlnoMed,
                setMedId,
                setSelectedMedicine,
                setMedStrengthList,
                setMedDosageList,
                setMedDoseList,
                setMedFrequencyList,
                setMedQuantityList,
                setMedDurationList,
                setMedicineListData,
                setMedicineNameList,
              }}}
              functions={{...{
                addPatientProperty,
                handleSubmitMedicine,
                handleMedicineDelete,
              }}}
              />)}

              {activeTab === 'assessment' && ( <AssessmentInvestigation
                value={{
                  ...{
                    prIssue,
                    diagnosis,
                    icdCode,
                    vatta,
                    pitta,
                    kapha,
                    prRemark,
                    selectedLabTest,
                    testTabNameList,
                    testTabTypeList,
                    labId,
                    slnoLab,
                    testTabName,
                    testTabType,
                    testTabInstruction,
                    testTabRemark,
                    labTestListData,
                  }
                }}
                setValue={{
                  ...{
                    setPrIssue,
                    setDiagnosis,
                    setIcdCode,
                    setVatta,
                    setPitta,
                    setKapha,
                    setPrRemark,
                    setSelectedLabTest,
                    setLabId,
                    setSlnoLab,
                    setTestTabName,
                    setTestTabType,
                    setTestTabInstruction,
                    setTestTabRemark,
                    setLabTestListData
                  }
                }}
                functions={{...{
                  addPatientProperty,
                }}}
              /> )}

              {activeTab === 'treatment' && ( <TreatmentDrop
                value={{...{
                  therapyNameList,
                  durationList,
                  frequencyList,
                  sessionList,
                  treatTherapyName,
                  treatDuration,
                  treatFrequency,
                  treatSession,
                  treatSpecificInstruction,
                  slnoTreat,
                  treatmentListData,
                  treatId,
                  selectedTreatment,
                  dietRestrictedListData,
                  dietRestrictedNameList,
                  dietRestricted,
                  dietRecommentedListData,
                  dietRecommentedNameList,
                  dietRecommented,
                  dietInstructionList,
                  dietInstruction,
                  lifeStyleList,
                  lifeStyle,
                }}}
                setValue={{...{
                  setTherapyNameList,
                  setDurationList,
                  setFrequencyList,
                  setSessionList,
                  setTreatTherapyName,
                  setTreatDuration,
                  setTreatFrequency,
                  setTreatSession,
                  setTreatSpecificInstruction,
                  setSlnoTreat,
                  setTreatId,
                  setSelectedTreatment,
                  setDietRestrictedListData,
                  setDietRestrictedNameList,
                  setDietRestricted,
                  setDietRecommentedListData,
                  setDietRecommentedNameList,
                  setDietRecommented,
                  setDietInstructionList,
                  setDietInstruction,
                  setLifeStyleList,
                  setLifeStyle
                }}}
                  functions={{...{
                    addPatientProperty,
                    handleSubmitTreat,
                    handleTreatmentDelete,
                  }}}
              />
              )}
               {activeTab === 'followup' && ( <div className="text-center">  Followup Details </div>)}
              </div>     
            </div>        
          </div>

        </div>
      </Tab.Container>
      <Modal show={transferPopup} onHide={() => setTransferPopup(!transferPopup)} backdrop={true} keyboard={false} size="xl"  centered>
        {/* <Modal.Header closeButton style={{backgroundColor:"#e3b075",color:"#fafafa"}}>
        <Modal.Title>{"Transfer IP"}</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>

        <TransferPopup 
        value={{...{
          patientDetails,
          admissionDate,
          admissionDuration,
          admissionReason,
        }}} 
        setValue={{...{
          setAdmissionDate,
          setAdmissionDuration,
          setAdmissionReason,
        }}} 
        />

        </Modal.Body>
        <Modal.Footer>
          <div className=" col-12 d-flex justify-content-end">
            <div className="col-1 p-2 me-1 buttonview">Edit</div>
            <div className="col-1 p-2 me-1 buttonview" onClick={() => setTransferPopup(!transferPopup)}>Cancel</div>
            <div className="col-1 p-2 me-2 buttonview" onClick={handleTransferConfirmation}>Confirm</div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PhysicianConsultation;
