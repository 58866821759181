import React, { useEffect, useState } from "react";
import Admission from "../IP Admissions/Admission";
import AdmissionRequest from "../IP Admissions/AdmissionRequest";
import Ward from "./Ward";
import PrivateRoom from "./PrivateRoom";
import "./Accommodation.css";
import WardForm from './components/ward/WardFrom'

const Accommodation = () => {
	const [activeRequest, setActiveRequest] = useState(true);
	const [addWard, setAddWard] = useState(false);
    const [wardId, setWardId] = useState(null);
	const [wardDetails, setWardDetails] = useState({
        ward_name: "",
        ward_type: "",
        rate_per_day: "",
        total_no_of_bed: "",
        eligible_for_insurance: false,
    });

	// State to track the active tab
	const [activeTab, setActiveTab] = useState("tab1");

	// Function to change the active tab
	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	// Function to change the active tab
	const handleAddWard = (tab) => {
		setAddWard((prev) => !prev);
		setWardDetails({
			ward_name: "",
			ward_type: "",
			rate_per_day: "",
			total_no_of_bed: "",
			eligible_for_insurance: false,
		})
	};

	return (
		<>
			{
				addWard ? 
					<WardForm 
						setAddWard={setAddWard}
						handleAddWard={handleAddWard}
						setWardId={setWardId}
						wardId={wardId}
						wardDetails={wardDetails}
						setWardDetails={setWardDetails}	
					/> : (
					<div className="tabs-container mt-0">
						{/* Tab headers */}
						<div className="tabs tab-btn my-4">
							<button
								className={`px-5 ${activeTab === "tab1" ? "active" : ""} ${activeRequest ? "active-request" : ""
									}`}
								onClick={() => {
									handleTabClick("tab1");
									setActiveRequest(true);
								}}
							>
								Ward
							</button>
							<button
								className={`px-4 ${activeTab === "tab2" ? "active" : ""} ${activeRequest ? "" : "active-request"
									}`}
								onClick={() => {
									handleTabClick("tab2");
									setActiveRequest(false);
								}}
							>
								Private Room
							</button>
						</div>

						{/* Tab content */}
						<div className="tab-content">
							{activeTab === "tab1" && <Ward wardId={wardId} handleAddWard={handleAddWard} />}
							{activeTab === "tab2" && <PrivateRoom />}
						</div>
					</div>
				)}
		</>
	);
};

export default Accommodation;
