import { useEffect, useState } from "react";
import Swal from "sweetalert2";

import useAccommodationServices from "../../../../services/api-services/accommodationServices";
import useAccountServices from "../../../../services/api-services/accountServices";


const WardFrom = (props) => {
    const { postWardAddition } = useAccommodationServices()
    const { getNewID } = useAccountServices()

    const {
        setAddWard,
        handleAddWard,
        setWardId,
        wardId,
        wardDetails,
        setWardDetails,	
    } = props;


    const handleChangeWardAddition = (e) => {
		const { name, value } = e.target;
		if (name === "eligible_for_insurance") {
			setWardDetails((prev) => ({
				...prev,
				[name]: !prev.eligible_for_insurance,
			}));

		} else {
			setWardDetails((prev) => ({
				...prev,
				[name]: value,
			}));
		}

	};

    const clearStarte = () => {

		setWardDetails({
			ward_name: "",
			ward_type: "",
			rate_per_day: "",
			total_no_of_bed: "",
			eligible_for_insurance: false,
		})

		setWardId(null)

	}

	useEffect(() => {
		getWardId()

	}, [])

	const getWardId = async () => {
		try {
			const response = await getNewID("ward_id")
			if (response?.success) {
				setWardId(response?.data?.complete_id)
			}
		} catch { }
	}

	const handleSubmitWardAddition = async () => {

		try {
			const data = JSON.stringify(wardDetails)
			const response = await postWardAddition(data)
			if (response?.success) {
				Swal.fire('', response?.message, 'success')
			}
			else {
				Swal.fire('', response?.message, 'error')
			}
		} catch (error) {
			console.log(error, "error=========");

		}

	};

    return (
        <>
            <div className="w-100 h-100 px-4">
                <div className="modal-header ward-addition rounded bg-light modal-top  py-3 px-3 ">
                    <h5 className="modal-title">Ward Addition</h5>
                    <div>
                        <button className="px-3 py-2 rounded" onClick={handleAddWard}>
                            Cancel
                        </button>
                        <button onClick={handleSubmitWardAddition} className="px-3 py-2 ms-3 rounded">Save</button>
                    </div>
                </div>

                <div className="w-75 w-100 modal-mid " role="document">
                    <div className="modal-content  px-3   ">
                        <div className="row mt-3">
                            <div className="col-md-6">
                                <label htmlFor="" className="modal-label">
                                    Ward ID
                                </label>
                                <div className="p-2 border bg-light rounded">
                                    <input type="text" name="ward_id" value={wardId} onChange={handleChangeWardAddition} className="w-100 h-100" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="" className="modal-label">
                                    Ward Name
                                </label>
                                <div className="p-2 border bg-light rounded ">
                                    <input type="text" name="ward_name" value={wardDetails.ward_name} onChange={handleChangeWardAddition} className="w-100 h-100" />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6">
                                <label htmlFor="" className="modal-label">
                                    Ward Type
                                </label>
                                <div className="p-2 border bg-light rounded">
                                    <select
                                        onChange={handleChangeWardAddition}
                                        name="ward_type"
                                        value={wardDetails.ward_type}
                                        className="w-100 h-100 border-0 outline-0 ward-detail-select"
                                        style={{
                                            appearance: "none",
                                            background: "none",
                                            padding: "0",
                                            margin: "0",
                                            boxShadow: "none",
                                            outline: "none",
                                        }}
                                    >
                                        <option value="">Select </option>
                                        <option value="general">general</option>
                                        <option value="special">special</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="" className="modal-label">
                                    Rate Per Day
                                </label>
                                <div className="p-2 border bg-light rounded">
                                    <input type="text" value={wardDetails.rate_per_day} onChange={handleChangeWardAddition} name="rate_per_day" className="w-100 h-100" />
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-6">
                                <label htmlFor="" className="modal-label">
                                    Total Bed
                                </label>
                                <div className="p-2 border bg-light rounded">
                                    <input type="text" onChange={handleChangeWardAddition} value={wardDetails.total_no_of_bed} name="total_no_of_bed" className="w-100 h-100" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor=""></label>
                                <div className="p-2  d-flex align-items-center ">
                                    <input type="checkbox" onChange={handleChangeWardAddition} checked={wardDetails.eligible_for_insurance} name="eligible_for_insurance" className="me-2" />
                                    <label htmlFor="" className="modal-label">
                                        Eligible for insurence
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-6">
                                <label htmlFor="" className="modal-label">
                                    Amenties Available
                                </label>
                                <div
                                    className=" border bg-light rounded"
                                    style={{ height: "50px" }}
                                >
                                    <textarea
                                        name=""
                                        id=""
                                        className="w-100 h-100 p-2 border-0 outline-0"
                                        style={{
                                            appearance: "none",
                                            background: "none",
                                            padding: "0",
                                            margin: "0",
                                            boxShadow: "none",
                                            borderRadius: "0",
                                            outline: "none",
                                            resize: "none", // Prevents resizing of the textarea
                                        }}
                                    ></textarea>
                                </div>

                                <label htmlFor="" className="mt-2 modal-label">
                                    Other Details
                                </label>
                                <div
                                    className=" border bg-light rounded"
                                    style={{ height: "70px" }}
                                >
                                    <textarea
                                        name=""
                                        id=""
                                        className="w-100 h-100 p-2 border-0 outline-0"
                                        style={{
                                            appearance: "none",
                                            background: "none",
                                            padding: "0",
                                            margin: "0",
                                            boxShadow: "none",
                                            borderRadius: "0",
                                            outline: "none",
                                            resize: "none", // Prevents resizing of the textarea
                                        }}
                                    ></textarea>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="" className="modal-label">
                                    Amenties Available
                                </label>
                                <div
                                    className=" border bg-light rounded"
                                    style={{ height: "145px" }}
                                >
                                    <textarea
                                        name=""
                                        id=""
                                        className="w-100 h-100 p-2 border-0 outline-0"
                                        style={{
                                            appearance: "none",
                                            background: "none",
                                            padding: "0",
                                            margin: "0",
                                            boxShadow: "none",
                                            borderRadius: "0",
                                            outline: "none",
                                            resize: "none", // Prevents resizing of the textarea
                                        }}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WardFrom


